<template>
  <transfere :activation="activeTransfere" @oga="retourTransfere" v-if="activeTransfere"/>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-6 pl-6">

    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c14 font-c5 text-left mb-6">
          <div class="w-1/6">
            Date
          </div>

          <div class="w-1/6 ml-4">
            Montant transféré
          </div>

          <div class="w-1/6 ml-4">
            Moyen d’opération
          </div>

          <div class="w-1/6 ml-4">
            Type d’opération
          </div>

          <div class="w-1/6 ml-4 text-left">
            Caisse/banque créditée
          </div>

          <div class="w-1/6 ml-4">
            Destinataire
          </div>

          <div class="w-1/6 ml-4">
            Signataire
          </div>
        </div>

        <div class="diviser" />

        <div
            v-if="charge"
            class="flex justify-center mt-16"
        >
          <easy-spinner
              type="dots"
              size="70"
          />
        </div>

        <div
            v-for="(item, index) in allHistory"
            :key="index"
            class="mt-5"
        >
          <div
              v-if="index > 0"
              class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
                class="mt-5"
                :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import transfere from '../../popup/transfere'

export default {
  name: "Index",
  components: {
    liste,
    transfere
  },

  props: {
    typeOption: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      historique: [],
      charge: true,
      activeTransfere: false,
      temoin: null
    }
  },

  computed: {
    allHistory: function () {
      if (this.typeOption !== this.temoin) {
        this.allInfo()
      }
      return this.historique
    }
  },

  created () {
    this.allInfo()
  },

  methods: {
    allInfo () {
      this.charge = true

      this.temoin = this.typeOption

      let query = '?id='  + this.$route.query.x
      if (this.typeOption !== null) {
        if (this.typeOption === 'Remise') {
          query = query + '&type=DEPOSIT'
        } else {
          query = query + '&type=TRANSFER'
        }
      }

      http.get(apiroutes.baseURL + apiroutes.historique + query)
          .then(response => {
            console.log('historique')
            console.log(response)
            this.historique = response.reverse()
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourTransfere (answer) {
      if (answer) {
        this.activeBillet = true
      }
      this.activeTransfere = false
    },

    selected (item) {
      console.log(item)
      window.open(item.pdf, '_blank');
      if (item.justify_url){
        window.open(item.justify_url, '_blank');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
